/**
 * |||| used for managing singular and plural strings
 * {key} used for dynamic strings
 * if none of the above are used the default string will be returned
 */

import { useStore } from 'datalayer/store'
import React, { isValidElement } from 'react'
import { isNullish } from 'utils/generalUtils'

export default function useTranslations() {
  const { translations: translation } = useStore()
  const app = 'UPLOAD'

  function hasTranslation() {
    return translation && translation.fields
  }

  // Overloads for getString
  function getString<T>(key: string, path: DotNestedKeys<T>, params: T): string
  function getString(
    key: string,
    defaultValue: string,
    params?: Record<string, unknown>
  ): string

  // Implementation of getString
  function getString<T>(
    key: string,
    defaultValue: string,
    params?: T | Record<string, unknown>
  ): string | JSX.Element {
    // Static strings with singular/plural
    if (defaultValue.includes('||||')) {
      return getPluralString(
        `${app}-${key}`,
        defaultValue,
        params as Record<string, string | number>
      )
    }

    // Static strings with template values
    if (defaultValue.match(/{.*}/)) {
      return getWithTemplateValues(
        translation?.fields?.[`${app}-${key}`]?.value || defaultValue,
        params as Record<string, string | number>
      )
    }

    // Dynamic item string (based on ids)
    if (params) {
      return getItemString(key, defaultValue as DotNestedKeys<T>, params as T)
    }

    if (!hasTranslation()) {
      return defaultValue
    }

    // Static strings
    return translation?.fields?.[`${app}-${key}`]?.value || defaultValue
  }

  const getWithTemplateValues = (
    template: string,
    params: Record<string, string | number>
  ) => {
    const paramKey = Object.keys(params)[0]

    const isReactElemet = isValidElement(params[paramKey])

    if (isReactElemet) {
      const words = template.split(' ')

      // We cant use jsx in this file since we use some typescript that is not compatible with .tsx files
      return React.createElement(
        'div',
        null,
        words.map((word) => {
          if (word === `{${paramKey}}`) {
            return React.createElement(
              'span',
              {
                style: { marginRight: 'var(--space-1)' }
              },
              params[paramKey]
            )
          }

          return `${word} `
        })
      )
    }

    return template.replace(`{${paramKey}}`, `${params[paramKey]}`)
  }

  const getPluralString = (
    key: string,
    template: string,
    params: Record<string, string | number>
  ): string | JSX.Element => {
    const paramKey = Object.keys(params)[0]

    const singular = template.split('||||')[0].trim()
    const plural = template.split('||||')[1].trim()

    const isSingular = params[paramKey] === 1

    if (!hasTranslation()) {
      if (isSingular) {
        return getWithTemplateValues(singular, params)
      }

      return getWithTemplateValues(plural, params)
    }

    if (isSingular) {
      const fieldKey = `${key}-singular`
      const field = translation?.fields[fieldKey]

      if (!field) {
        return getWithTemplateValues(singular, params)
      }

      return getWithTemplateValues(field.value, params)
    }

    const fieldKey = `${key}-plural`
    const field = translation?.fields[fieldKey]

    if (!field) {
      return getWithTemplateValues(plural, params)
    }

    return getWithTemplateValues(field.value, params)
  }

  const getItemString = <T>(id: string, path: DotNestedKeys<T>, item: T) => {
    if (!hasTranslation()) {
      return getValue(path, item)
    }

    const key = `${id}-${(path as string).replace(/\./g, '-')}`

    const field = translation?.fields[key]

    if (isNullish(field?.value)) {
      return getValue(path, item)
    }

    return field.value
  }

  return { getString }
}

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`

type DotNestedKeys<T> = (
  T extends object
    ? {
        [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}`
      }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never

function getValue(path: string, object) {
  return path.split('.').reduce((o, i) => o?.[i], object)
}
