export const EASE_IN_OUT = [0.455, 0.03, 0.515, 0.955]

// STANDARD -----------------------------------------
export const staggerAnimation = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.1
    }
  }
}

export const fadeAnimation = {
  hidden: {
    opacity: 0,
    transition: { ease: EASE_IN_OUT, duration: 0.3 }
  },
  show: { opacity: 1, transition: { ease: EASE_IN_OUT, duration: 0.3 } }
}

export const optionsAnimation = {
  hidden: {
    opacity: 0,
    transition: { ease: EASE_IN_OUT, duration: 0.3 }
  },
  show: {
    opacity: 1,
    transition: { ease: EASE_IN_OUT, duration: 0.5, delay: 0.3 }
  }
}

export const errorAnimation = {
  hidden: {
    opacity: 0,
    x: '-50%',
    y: '-50%',
    bottom: -40,
    transition: { duration: 0.3, ease: EASE_IN_OUT }
  },
  show: {
    opacity: 1,
    x: '-50%',
    y: '-50%',
    bottom: 0,
    transition: { duration: 0.3, ease: EASE_IN_OUT }
  }
}

export const errorTextAnimation = {
  hidden: {
    opacity: 0,
    transition: { ease: 'easeInOut', duration: 0.15 }
  },
  show: {
    opacity: 1,
    transition: { ease: 'easeInOut', duration: 0.15 }
  }
}


export const modalAnimation = {
  hidden: {
    opacity: 0,
    x: '-50%',
    y: '-50%',
    scale: 0.8,
    transition: { duration: 0.18, ease: EASE_IN_OUT }
  },
  show: {
    opacity: 1,
    x: '-50%',
    y: '-50%',
    scale: 1,
    transition: { duration: 0.15, ease: EASE_IN_OUT }
  },
  exit: {
    opacity: 0,
    x: '-50%',
    y: '-50%',
    scale: 0.8,
    transition: { duration: 0.25, ease: EASE_IN_OUT }
  }
}

export const backdropAnimation = {
  hidden: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'easeOut' }
  },
  show: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'easeInOut' }
  },
  exit: { opacity: 0, transition: { duration: 0.2, ease: 'easeIn' } }
}

export const postParentAnimation = {
  hidden: { opacity: 0, transition: { ease: EASE_IN_OUT, duration: 0.5 } },
  show: {
    opacity: 1,
    transition: {
      ease: EASE_IN_OUT,
      duration: 0.5,
      delayChildren: 0.2,
      delay: 0.2
    }
  }
}

export const continueInstagram = {
  hidden: {
    y: 100,
    transition: { ease: EASE_IN_OUT, duration: 0.15 }
  },
  show: {
    y: 0,
    transition: { ease: EASE_IN_OUT, duration: 0.3 }
  }
}

export const postAnimation = {
  hidden0: {
    scale: 0.8,
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  },
  hidden1: {
    scale: 0.8,
    marginLeft: -80,
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  },
  hidden2: {
    scale: 0.8,
    marginLeft: 80,
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  },
  hidden3: {
    scale: 0.8,
    marginLeft: 140,
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  },
  hidden4: {
    scale: 0.8,
    marginLeft: -140,
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  },
  show0: {
    scale: 1,
    transition: { ease: EASE_IN_OUT, duration: 0.5, delay: 0.15 }
  },
  show1: {
    scale: 1,
    marginLeft: 10,
    transition: { ease: EASE_IN_OUT, duration: 0.5, delay: 0.25 }
  },
  show2: {
    scale: 1,
    marginLeft: -10,
    transition: { ease: EASE_IN_OUT, duration: 0.5, delay: 0.35 }
  },
  show3: {
    scale: 1,
    marginLeft: -10,
    transition: { ease: EASE_IN_OUT, duration: 0.5, delay: 0.45 }
  },
  show4: {
    scale: 1,
    marginLeft: 10,
    transition: { ease: EASE_IN_OUT, duration: 0.5, delay: 0.55 }
  }
}

export const loadingParent = {
  hidden: {
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  },
  show: {
    transition: { ease: EASE_IN_OUT, duration: 0.5, staggerChildren: 0.1 }
  },
  exit: {
    transition: { ease: EASE_IN_OUT, duration: 0.5, staggerChildren: 0.1 }
  }
}

export const loadingChild = {
  hidden: {
    opacity: 0,
    y: 40,
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  },
  show: { opacity: 1, y: 0, transition: { ease: EASE_IN_OUT, duration: 0.5 } },
  exit: {
    opacity: 0,
    y: -40,
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  }
}
