export const POST_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
}

export const DELETE_STEPS = {
  AUTHENTICATION: 0,
  SELECT_POST: 1
}

export const MAX_CAPTION_LENGTH = 2200
