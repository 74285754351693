import styled, { css } from 'styled-components'

import React from 'react'

type Props = {
  alignment?: 'flex-start' | 'center' | 'flex-end'
  direction?: 'row' | 'column'
  children: React.ReactNode
}

export default function ButtonGroup({
  alignment,
  direction = 'row',
  children,
  ...props
}: Props) {
  return (
    <Style $alignment={alignment} $direction={direction} {...props}>
      {children}
    </Style>
  )
}

export const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $alignment }) => $alignment};
  grid-gap: var(--spacing-sm);
  width: 100%;

  ${(props) =>
    props.$direction === 'column' &&
    css`
      flex-direction: column;
    `}
`
