import PoweredBy, {
  Style as PoweredByStyle
} from 'components/PoweredBy/PoweredBy'

import { EASE_IN_OUT } from 'ui/animations/animations'
import React from 'react'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export default function Body({ children, ...props }) {
  return (
    <Style {...props}>
      <motion.div
        className="body-container"
        variants={bodyAnimation}
        initial={'hidden'}
        animate={'show'}
      >
        <div className="body-inner">{children}</div>
        <PoweredBy className="desktop" />
      </motion.div>
    </Style>
  )
}

export const Style = styled(motion.div)`
  position: relative;

  .body-container {
    z-index: 1;
    height: -webkit-fill-available;
    height: 100dvh;

    ${media.greaterThan('medium')`
      margin: 0px auto;
      position: fixed;
      max-height: 90vh;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: auto;
    `}
  }

  .body-inner {
    overflow: hidden;
    background: #fff;

    ${media.greaterThan('medium')`
      box-shadow: 0px 5px 50px -5px rgb(0 0 0 / 15%);
      border: 1px solid rgb(221 221 221 / 50%);
      border-radius: var(--border-radius);
    `}
  }

  ${PoweredByStyle} {
    &.desktop {
      display: none;
      ${media.greaterThan('medium')`
        left: 50%;
        transform: translateX(-50%);
        bottom: -40px;
        position: absolute;
        display: flex;
      `}
    }
  }
`

export const bodyAnimation = {
  hidden: {
    opacity: 0,
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  },
  show: {
    opacity: 1,
    transition: { ease: EASE_IN_OUT, duration: 0.5 }
  }
}
