import styled, { css } from 'styled-components'

import Loader from 'components/Loader/Loader'
import React from 'react'
import { motion } from 'framer-motion'

export type Props = {
  color?: 'primary' | 'light' | 'plain'
  loading?: boolean
  disabled?: boolean
  className?: string
  type?: string
  expanded?: boolean
  size?: 'default' | 'small'
  children?: React.ReactNode
  onClick?: (e?: any) => void
}

export default function Button({
  color = 'primary',
  disabled = false,
  loading = false,
  children,
  expanded = false,
  size = 'default',
  ...props
}: Props) {
  const onClick = () => {
    if (loading) {
      return
    }

    if (typeof props.onClick === 'function') {
      props.onClick()
    }
  }

  return (
    <Style
      onClick={onClick}
      color={color}
      loading={loading}
      disabled={disabled}
      expanded={expanded ? 1 : 0}
      size={size}
      {...props}
    >
      {!loading && children}
      {loading && <Loader />}
    </Style>
  )
}

export const Style = styled(motion.button)`
  color: var(--button-text-light);
  padding: 13px 15px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s background-color var(--transition),
    0.15s border var(--transition);
  user-select: none;
  background: none;
  white-space: nowrap;
  font-size: 16px;
  height: 47px;

  svg {
    height: 16px !important;
    width: 16px !important;
    min-width: 16px !important;

    &:first-child:not(:last-child) {
      margin-right: 8px;
    }

    &:last-child:not(:first-child) {
      margin-left: 8px;
    }
  }

  &.large-icon {
    justify-content: space-between;

    svg {
      height: 24px !important;
      width: 24px !important;
      min-width: 24px !important;
    }
  }

  &.continue-button {
    padding-right: 5px;
  }

  // Colors -------------------------------------

  ${(props) =>
    props.color === 'primary' &&
    css`
      background-color: var(--primary);
      color: var(--primary-text) !important;
      border: 1px solid var(--primary);

      &:hover {
        background-color: var(--primary-hover);
        border: 1px solid var(--primary-hover);
      }

      &:active {
        background-color: var(--primary-active);
        border: 1px solid var(--primary-active);
      }

      svg {
        color: var(--primary-text) !important;
      }
    `}

  ${(props) =>
    props.color === 'light' &&
    css`
      border: 1px solid var(--light);
      background-color: var(--light);
      color: var(--text);

      svg {
        color: var(--text);
      }

      &:hover {
        background-color: var(--light-hover);
        border: 1px solid var(--light-hover);
      }

      &:active {
        background-color: var(--light-active);
        border: 1px solid var(--light-active);
      }
    `}

    ${(props) =>
    props.color === 'plain' &&
    css`
      background-color: unset;
      border: unset !important;
      color: var(--text);

      svg {
        color: var(--text);
      }

      &:hover {
        background-color: var(--light-hover);
      }

      &:active {
        background-color: var(--light-active);
      }
    `}

    ${(props) =>
    props.size === 'small' &&
    css`
      padding: 0px 12px;
      height: 34px;
      font-size: 14px;
    `}

    
  

    ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}

    ${({ loading }) =>
    loading &&
    css`
      cursor: default;
      pointer-events: none;
    `}

    ${(props) =>
    props.expanded &&
    css`
      width: 100%;
    `}

    ${(props) =>
    props.noEvents &&
    css`
      pointer-events: none;
    `}
`
