import React from 'react'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'

export default function CompanyLogo({ ...props }) {
  const company = useStore((state) => state.company)

  return (
    <>
      {company?.image?.location && (
        <Style {...props}>
          <div className="company-profile-picture">
            <img
              src={`${company?.image?.location}?class=240`}
              alt={company?.username}
            />
          </div>
        </Style>
      )}
    </>
  )
}

export const Style = styled(motion.div)`
  display: flex;
  align-items: center;
  grid-gap: 15px;
  display: flex;
  color: #000;
  letter-spacing: -0.03em;

  ${media.lessThan('medium')`
    width: 100%;
    justify-content: center;
  `}

  .company-profile-picture {
    height: 70px;
    width: 70px;
    min-width: 70px;
    border-radius: 70px;
    position: relative;

    img {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: var(--border-radius);
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 70px;
    }
  }
`
