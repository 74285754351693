import { CircleLoader } from 'icons'
import React from 'react'
import styled from 'styled-components'

type Props = {
  type?: string
  size?: string
}

export default function Loader({ type, size, ...props }: Props) {
  return (
    <Style type={type} size={size} {...props}>
      <CircleLoader />
    </Style>
  )
}

export const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--text);
  }
`
