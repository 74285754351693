import styled, { css } from 'styled-components'

import React from 'react'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import { slugify } from 'utils/generalUtils'
import { useStore } from 'datalayer/store'

export default function PoweredBy({ ...props }) {
  const company = useStore((state) => state.company)
  const mode = useStore((state) => state.mode)

  return (
    <>
      {!company?.disableCevoidBranding && (
        <Style mode={mode} {...props}>
          <a
            href={`https://cevoid.com?source=upload&brand=${slugify(
              company?.username
            )}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Powered by <strong>cevoid</strong>
          </a>
        </Style>
      )}
    </>
  )
}

export const Style = styled(motion.div)`
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    align-items: baseline;
    display: inline-flex;
    grid-gap: 4px;
    text-decoration: none;
    color: #000;
    justify-content: center;
    transition: 0.3s all ease-in-out;
    margin: 0px auto;
    font-size: 12px;

    ${(props) =>
      props.mode === 'modal' &&
      css`
        ${media.greaterThan('medium')`
          color: #fff !important;
        `}
      `}

    strong {
      letter-spacing: 0em;
      font-size: 14px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &.mobile {
    display: flex;
    ${media.greaterThan('medium')`
      display: none;
    `}
  }
`
