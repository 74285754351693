import React, { useEffect, useState } from 'react'

import { Alert } from 'ui/icons'
import { errorAnimation } from 'ui/animations/animations'
import media from 'styled-media-query'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useStore } from 'datalayer/store'
import useTranslations from 'hooks/useTranslations'

export default function Error({ ...props }) {
  const [display, setDisplay] = useState(false)
  const setError = useStore((state) => state.setError)
  const error = useStore((state) => state.error)
  const customErrorMessage = useStore((state) => state.customErrorMessage)
  const { getString } = useTranslations()

  useEffect(() => {
    setDisplay(true)

    setTimeout(() => {
      setDisplay(false)
      setTimeout(() => {
        setError(undefined)
      }, 500)
    }, 4000)
  }, [])

  return (
    <Style
      {...props}
      variants={errorAnimation}
      initial="hidden"
      animate={display ? 'show' : 'hidden'}
      exit="hidden"
    >
      <div className="error-icon">
        <Alert />
      </div>
      {error === 'max-upload' && (
        <div className="error-message">
          {getString('maxUpload', 'Max 5 uploads at the same time')}
        </div>
      )}
      {error === 'max-filesize' && (
        <div className="error-message">
          {getString('maxFileSize', 'File size is too large')}
        </div>
      )}
      {error === 'file-invalid-type' && (
        <div className="error-message">
          {getString('fileInvalidType', 'Invalid file type')}
        </div>
      )}
      {error === 'upload-error' && (
        <div className="error-message">
          {getString('uploadError', 'Could not upload your posts')}
        </div>
      )}
      {error === 'custom' && (
        <div className="error-message">{customErrorMessage}</div>
      )}
    </Style>
  )
}

const Style = styled(motion.div)`
  text-align: center;
  display: flex;
  grid-gap: var(--spacing-sm);
  justify-content: center;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  position: fixed;
  bottom: 40px;
  left: 50%;
  color: #fff;
  border-radius: 100px;
  transform: translateX(-50%);
  align-items: center;
  background: var(--negative);
  z-index: 99999;
  max-width: 90%;
  width: 100%;

  ${media.greaterThan('medium')`
    white-space: nowrap;
    width: auto;
  `}

  .error-icon {
    display: flex;

    svg {
      height: 20px;
      width: 20px;
      min-width: 20px;
    }
  }
`
